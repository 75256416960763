.field {
  margin-bottom: 1.5rem;

  small {
    &.p-error {
      margin-left: 0.7rem;
    }
  }
}

.p-sidebar-left {
  .p-sidebar {
    top: -1.75rem;
    min-width: 200px;
    width: 200px !important;
    height: calc(100% - 3.5rem);
    border-width: 0 1px 0 0;
    // transform: none !important;

    .p-sidebar-header {
      display: none;

      & + .p-sidebar-content {
        padding: 0 0 0.25rem;
        background: #001529;
      }
    }
  }
}

.p-sidebar-mask {
  top: 3.5rem;
}

.p-menu {
  padding-top: 0 !important;

  .p-menu-list {
    .p-submenu-header {
      font-size: 1.2rem;
    }

    .p-menuitem-link {
      justify-content: end;
      border-right: 2px solid transparent;

      &:focus {
        box-shadow: none;
      }
    }

    .p-menuitem {
      text-indent: 1rem;

      &.menu-active {
        .p-menuitem-link {
          border-right: 2px solid #07d3af;

          .p-menuitem-text {
            font-weight: bold;
            color: #6296e9;
          }
        }
      }
    }
  }
}

.p-panelmenu {
  .p-panelmenu-panel {
    .p-panelmenu-header {
      opacity: 0.7;

      &:hover,
      &.p-highlight {
        opacity: 1;
      }

      .p-panelmenu-header-link {
        color: #ffffff;
        font-weight: normal;
        background: #001529;
        border-top-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border: none;

        .p-menuitem-text,
        .p-menuitem-icon,
        i {
          color: #ffffff;
        }

        &:not(.p-disabled) {
          &:hover {
            background-color: #1890ff;
            opacity: 1;

            .p-menuitem-text {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .p-panelmenu-content {
    padding: 0;
    background: transparent;
    border: none;

    .p-menuitem {
      .p-menuitem-link {
        color: #ffffff;

        .p-menuitem-text,
        .p-menuitem-icon {
          color: #ffffff;
        }

        &:not(.p-disabled) {
          &:hover {
            background-color: #1890ff;
            opacity: 1;

            .p-menuitem-text {
              color: #ffffff;
            }
          }
        }
      }

      &.menu-active {
        background-color: #1890ff;
      }
    }

    .p-submenu-list {
      padding: 0 !important;

      .p-menuitem {
        // padding-left: 1rem;
      }
    }
  }
}

.p-toast-message {
  .p-toast-message-content {
    align-items: center;

    .p-toast-message-text {
      .p-toast-detail {
        white-space: pre-wrap;
      }
    }
  }
}

.p-breadcrumb {
  padding: 0.5rem 3px 3px !important;
  background: transparent !important;

  ul {
    li {
      .p-menuitem-link {
        padding: 2px;
        border-radius: 2px;

        .p-menuitem-text {
          color: #6c757d;
        }
      }

      a {
        &.p-menuitem-link {
          .p-menuitem-text {
            color: #007bff;
          }
        }
      }
    }
  }
}

.p-tabview {
  .p-tabview-nav {
    border-width: 0 0 2px 0;

    li {
      margin-bottom: -1px;

      .p-tabview-nav-link {
        justify-content: center;
        padding: 0.75rem 1.25rem;
        min-width: 5rem;
        border-width: 0 0 2px 0;
      }

      &.p-highlight {
        .p-tabview-nav-link {
          color: #007bff;
          border-color: #ffffff #ffffff #007bff #ffffff;
        }
      }
    }
  }

  .p-tabview-panels {
    padding: 0;
  }
}

.p-tabmenu {
  .p-tabmenu-nav {
    background: transparent;
    border-width: 0 0 1px 0;

    .p-tabmenuitem {
      .p-menuitem-link {
        margin: 0 0 -2px 0;
        text-align: center;
        background: transparent;
        border-width: 1px 1px 2px;
        border-color: transparent;
      }

      &:not(.p-highlight):not(.p-disabled):hover,
      &.p-highlight {
        .p-menuitem-link {
          color: #007bff;
          background: transparent;
          border-color: #ffffff #ffffff #007bff #ffffff;
          border-width: 1px 1px 2px;
        }
      }
    }
  }
}

.p-datepicker {
  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: none;
      border-right: 1px solid #dee2e6;
    }
  }
}

.p-datatable {
  &.size-sm {
    .p-datatable-table {
      .p-datatable-thead {
        tr {
          th {
            padding: 0.5rem 0.3rem;
          }
        }
      }

      .p-datatable-tbody {
        tr {
          td {
            padding: 0.2rem 0.3rem;
          }
        }
      }
    }
  }

  &.table-empty {
    .p-datatable-wrapper {
      overflow: hidden;

      .p-datatable-emptymessage {
        display: none;
      }
    }

    .p-paginator {
      display: none;
    }
  }

  &.p-datatable-striped {
    .p-datatable-tbody {
      & > tr {
        &.p-row-odd {
          background: #f9f9f9;
        }
      }
    }
  }

  .p-datatable-table {
    width: 100%;
    table-layout: auto;

    .p-datatable-thead {
      z-index: 2;

      tr {
        th {
          background: #f9f9f9;

          &.p-frozen-column {
            z-index: 1;
          }
        }
      }
    }

    .p-datatable-tbody {
      tr {
        // border-bottom: 1px solid #dee2e6;

        &:not(.p-highlight):not(.p-datatable-emptymessage):hover {
          background: #e6f7ff !important;

          td {
            background: #e6f7ff !important;
          }
        }

        td {
          // align-items: flex-start !important;
          padding: 0.7rem;
          word-break: break-word;

          &.p-frozen-column {
            z-index: 1;
          }

          .p-column-title {
            min-width: 15%;
          }
        }

        // &:nth-of-type(even) {
        //   td {
        //     background-color: #f9f9f9;
        //   }
        // }

        &.p-datatable-emptymessage {
          td {
            width: 100%;
            color: #bb0000;
            text-align: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .p-datatable {
    &:not(.p-datatable-scrollable) {
      .p-datatable-tbody {
        & > tr {
          border-bottom: 1px solid #dee2e6;

          & > td {
            border: none;
          }
        }
      }
    }
  }
}

.p-overlaypanel {
  box-shadow: 0px 0px 10px #a9a9a9;

  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-button {
  &.p-invalid {
    border-color: #dc3545 !important;
  }

  &.p-button-gray {
    background: #ced4da;

    &:enabled {
      &:hover,
      &:active {
        background: rgba(108, 117, 125, 0.04);
        color: #ced4da;
        border: 1px solid;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem #ced4da52;
      }
    }

    &.p-button-outlined {
      background-color: transparent;
      color: #6c757d;
      border-color: #ced4da;

      &:enabled {
        &:hover,
        &:active {
          background: #ced4da52;
          color: #6c757d;
          border-color: #ced4da;
        }

        &:focus {
          box-shadow: 0 0 0 0.2rem #ced4da52;
        }
      }
    }
  }
}

.p-checkbox {
  &.p-checkbox-disabled {
    .p-checkbox-box {
      .p-checkbox-icon {
        color: #b7b7b7;
      }
    }
  }
}

// .p-disabled,
// .p-component:disabled {
//   // background-color: #eeeeee;
//   cursor: not-allowed;
//   opacity: 0.4;
// }

.p-disabled,
.p-checkbox-disabled {
  color: #999999 !important;
  background-color: #eeeeee !important;
  border-color: #ced4da !important;
  cursor: not-allowed;
  opacity: 0.6;
}

.p-button,
.p-inputtext,
.p-dropdown,
.p-multiselect {
  border-radius: 0;
}

.p-inputnumber-input,
.p-password-input {
  width: 100%;
}

.rotate-icon {
  .p-button-icon {
    transform: rotate(-90deg);
  }
}

.p-sm {
  .p-inputtext {
    padding: 0.1rem 0.3rem;
  }

  .p-calendar {
    .p-button {
      &.p-datepicker-trigger {
        padding: 0.1rem 0;
        width: 2rem;

        .p-button-icon {
          font-size: 12px;
        }
      }
    }
  }

  &.p-dropdown-panel {
    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        .p-dropdown-item,
        .p-dropdown-empty-message {
          padding: 0.1rem 0.75rem;
        }
      }
    }
  }
}

.p-multiselect-panel {
  .p-multiselect-header {
    padding: 0.25rem 1.5rem;
  }
}

.p-dropdown {
  .p-dropdown-label {
    &.p-placeholder {
      color: #bbbbbb;
    }
  }
}

.p-multiselect {
  .p-multiselect-label {
    &.p-placeholder {
      color: #bbbbbb;
    }
  }
}

.ui-bg-transparent {
  .p-inputtext {
    background-color: transparent;
  }
}

.ui-text-right {
  .p-inputtext {
    text-align: right;
  }
}
