.front-layout-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.back-layout-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  &.layout-sidebar-fixed {
    &.layout-sidebar-show {
      .layout-sidebar {
        flex-shrink: 0;
        width: 200px;
        height: 100%;
      }
    }
  }

  .layout-topbar {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 3.5rem;
    color: #ffffff;
    background-color: #001529;
    box-shadow: 0 0 4px rgb(0 0 0 / 25%);
    z-index: 1;

    .layout-title {
      margin-left: 1rem;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }

  .layout-body {
    display: flex;
    // flex-direction: column;
    flex: 1 1 auto;
    background-color: #ffffff;
    overflow-y: auto;

    .layout-sidebar {
      width: 0;
      transition: all 0.3s ease;
    }

    .layout-body-content {
      flex: 1 1 auto;
      padding: 1rem 1.5rem;
      width: 100%;
      overflow-x: auto;
    }
  }
}

.layout-logo {
  padding-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid #eee;

  img {
    max-width: 100px;
    width: 100%;
  }
}
