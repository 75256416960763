html {
  height: 100%;
  font-size: 14px;
  font-family: "Noto Sans TC", sans-serif;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  color: var(--text-color);
}

.white-space-prewrap {
  white-space: pre-wrap;
}

.pb-100 {
  padding-bottom: 100%;
}
.w-0 {
  width: 0;
}

.bg-yellow {
  background-color: yellow;
}

.shadow {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

::placeholder {
  color: #bbbbbb;
}

@for $i from 1 through 40 {
  $flexbasis: 10px * $i;
  .flex-basis-#{$i} {
    flex-basis: $flexbasis !important;
  }
}

.bg-complete {
  background-color: #d0e0e3 !important;
}

.bg-shipped {
  background-color: #caf5e1 !important;
}

.bg-unshipped {
  background-color: #ffe599 !important;
}

/* sort start */
.sort-wrap {
  margin: auto;
  max-width: 30rem;
  width: 90%;

  .sort-item {
    margin: 0.5rem;
    color: #000000;
    border: 1px solid #dee2e6;
  }
}

.sort-item {
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  text-align: center;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #007bff;
  cursor: grab;
  z-index: 1110;
}
/* sort end */
